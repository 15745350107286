import React from 'react';
import { NavLink } from 'react-router-dom'

const Slider = (props) => {
    const { img, titulo, mensaje, ruta, styles} = props.slider;

    return ( 
        <li style={{ height: '500px'}}>
            <img src={img} alt="" style={styles} />
            <div className="flex-caption">
                <h3>{titulo}</h3>
                <p className="text-justify"><i>{mensaje}</i></p>
                <NavLink to={ruta} className="btn btn-theme">Leer más...</NavLink>
            </div>
        </li>
     );
}
 
export default Slider;