import React from 'react';
import { NavLink } from 'react-router-dom';

const Box = (props) => {
    const { titulo , icono, mensaje, ruta } = props.box;
    return ( 
        <div className="col-lg-3">
			<div className="box">
				<div className="box-gray aligncenter">
					<h4>{titulo}</h4>
					<div className="icon">
						<i className={icono}></i>
					</div>
					<p className="text-justify" dangerouslySetInnerHTML={{__html : mensaje}}>
					</p>
				</div>
				<div className="box-bottom">
                    <NavLink to={ruta}>Leer más...</NavLink>
				</div>
			</div>
		</div>
     );
}
 
export default Box;