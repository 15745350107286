import React, { Fragment } from 'react';


const Footer = () => {
    return ( 
        <Fragment>
            <footer>
	            <div id="sub-footer">
		            <div className="container">
			            <div className="row">
				            <div className="col-lg-4">
					            <div className="copyright">
						            <a href="#!" className="aviso-view">Aviso de Privacidad</a>
						            <div className="credits">
							            <p>&copy; Copyright 2018 BRAC Firma Legal.</p>
						            </div>
					            </div>
				            </div>
				            <div className="col-lg-4">
					            <div className="direction">
									<p>Blvd. Benito Juárez, Edif. 45, Planta Baja, Desp 20,<br/> Colonia Las Palmas, C.P. 62050, Cuernavaca, Morelos</p>
						            <p>T. 777 242 57 42 | 777 318 59 71</p>
					            </div>
				            </div>
				            <div className="col-lg-4">
					            <ul className="social-network">
						            <li><a href="https://www.facebook.com/BRACfirmalegal" data-placement="top" title="Facebook"><i className="fa fa-facebook fa-2x"></i></a></li>
						            <li><a href="https://twitter.com/BRACfirmalegal" data-placement="top" title="Twitter"><i className="fa fa-twitter fa-2x"></i></a></li>
						            <li><a href="https://www.linkedin.com/in/christian-bonola-roman-5b9273125/" data-placement="top" title="Linkedin"><i className="fa fa-linkedin fa-2x"></i></a></li>
					            </ul>
				            </div>
			            </div>
		            </div>
	            </div>
            </footer>

<div className="modal fade aviso-privacidad" tabIndex="-1" role="dialog">
	<div className="modal-dialog" role="document">
		<div className="modal-content">
			<div className="modal-header">
				<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
				<h4 className="modal-title">Aviso de Privacidad</h4>
			</div>
			<div className="modal-body">
				<div className="row">
					<div className="col-lg-12 text-justify">
						<p>En correlación con los valores que distinguen está Firma de Abogados y con fundamento en lo dispuesto por la Ley de Datos Personales, la protección de sus datos personales y su privacidad, son prioridad de <b>BRAC Firma Legal</b>, asegurándonos del correcto uso y cuidado, bajo los más altos estándares legales, de humanidad, de ética, de confianza y profesionalismo. </p>
						<p>Consecuentemente, es nuestra necesario compartir nuestra política de privacidad y protección de datos personales, la cual está disponible en la página de internet, identificada como www.bracfirmalegal.com poniéndose de forma permanente a disposición general para su consulta. </p>
						<p>En general, los únicos datos que <b>BRAC Firma Legal</b> recaba, son nombres, direcciones de domicilio, medios electrónicos (e-mail), teléfonos, y en algunos casos, datos fiscales, con la única finalidad, de realizar consultas vía electrónica, ejecución y operación de servicios, solicitud de productos o servicios o pago por los productos o servicios proporcionados por esta Firma de Abogados.</p>
						<p>Para el caso aislado de que exista un fin distinto para recabar datos personales a los descritos en el renglón anterior, el responsable de la información lo comunicará de forma expresa o tácita al titular para su consentimiento y autorización.</p>
						<p>Asimismo, en cualquier momento usted puede eliminar o limitar el uso y divulgación de sus datos personales, así como dejar de recibir noticias, notificaciones o información relacionada con la actividad de esta Firma de Abogados, mediante el ejercicio de los derechos de acceso, rectificación, cancelación u oposición que la Ley establece, a través de petición formal por escrito, en el domicilio de <b>BRAC Firma Legal</b>, y siguiendo el procedimiento establecido en el Ordenamiento Legal multicitado.</p>
						<p>Sin embargo, como medio de fácil acceso a manifestar de manera expresa su inconformidad al respecto, podrá formular su solicitud vía electrónica a la dirección de correo administracion@bracfirmalegal.com puntualizando la titular del acto de forma fundada y acorde a lo señalado por la Ley su negativa, entendiéndose que de no recibir oposición dentro del término máximo de quince días, contados a partir de la fecha en que se recibió la solicitud, se entenderá que usted como titular, consiente tácitamente el tratamiento de sus datos personales, conforme a lo enunciado y alcances legales del presente aviso de privacidad.</p>
						<p>Cualquier modificación a este aviso de privacidad, el responsable se obliga a comunicar a los titulares los cambios realizados, mediante la publicación y al alcance de todos, en el portal de internet del responsable: www.bracfirmalegal.com. dentro de los cinco días siguientes a su reforma.</p>
						<p>En caso de revocación al consentimiento anteriormente otorgado al responsable, el mecanismo para revocarlo, no tendrá efectos retroactivos y será invalida su solicitud, para el caso de que la oposición o revocación, tenga como efecto desvirtuar o disminuir obligaciones contractuales derivadas de la relación Responsable-Cliente. </p>
						<p>Nuestra Firma Legal señala para atención y seguimiento a las solicitudes de los titulares para el ejercicio de los derechos ARCO, enmarcados por la Ley Federal de Protección de Datos Personales en posesión de particulares -Acceso, Rectificación Cancelación y Oposición, así como limitación al uso o divulgación de datos y revocación del consentimiento), cualquiera de las modalidades antes descritas; no obstante, para el caso de que la solicitud se realice a través de medios electrónicos, deberá contar con <b>ACUSE DE RECIBO</b>, de lo contrario, se entenderá como no efectuada dicha oposición. </p>
						<p>Comprendido en su totalidad el alcance legal y contenido del presente Aviso de Privacidad, otorgo mi pleno consentimiento para que los datos personales proporcionados sean tratados conforme lo señalado en este apartado legal.</p>
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
			</div>
		</div>
	</div>
</div>

            <script src="./js/jquery.js"></script>

        </Fragment>
     );
}
 
export default Footer;