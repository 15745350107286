import React, { Component, Fragment } from 'react';
import {  withRouter } from 'react-router-dom';
import BreadCrumb from './BreadCrumb'
import axios from 'axios';

class Articulo extends Component {
    stateVacio = {
        titulo : 'SALARIO MÍNIMO 2018',
        url_img : 'http://www.bracfirmalegal.com/dashboard/files/portada/cortada/grande/salario minimo 2018.jpg',
        contenido : `En su informe más reciente, la Organización Internacional del Trabajo (OIT) mostró la realidad de México y lo consideró como el país de América Latina con la peor evolución del salario real, con una estimación de caída de 12 por ciento.`,
        fecha_publicacion : 'Viernes 16 de Febrero del 2018',
    }
    state = { 
        articulo : {}
     }

    async componentDidMount(){
        const { id } = this.props.match.params;
        console.log(id);
        const url = `http://www.bracfirmalegal.com/blog-json.php?articulo=${id}`;
        const headers = {
            'Content-type' : 'application/json',
            'X-API-KEY' : 'hQwiBDmtnENJ3nRKg9yCAA2DUoD}TJf8'
        }
        const request = await axios.get(url,{
            headers
        })

        const art = request.data;
        console.log(art);
        const articuloObjeto = {
            titulo : `${art['titulo']}`,
            url_img : `http://www.bracfirmalegal.com/dashboard/files/portada/cortada/grande/${art['portada']}`,
            url_detalle : `/articulo/${art['id_articulo_md5']}`,
            fecha_publicacion : `${art['fecha_publicacion']}`,
            contenido : `${art['contenido']}`
        }
        //Cambiar y validar por la informacion de la peticion
        this.setState({
            articulo : articuloObjeto
        })
    }

    render() { 
        function setContenidodom(blog_contenido){
            return { __html : blog_contenido }
        }

        const { titulo, url_img , contenido,fecha_publicacion } = this.state.articulo;
        const { history } = this.props;

        return ( 
            <Fragment>
                <BreadCrumb titulo="Blog"/>
                <section id="content">
			        <div className="container">
                        <div className="row">
					        <div className="col-md-12">
						        <article>
								        <div className="post-image">
									        <div className="post-heading">
										        <h3><a href="#!">{titulo}</a></h3>
									        </div>
									        <img src={url_img} alt={titulo} />
								        </div>
								        <p dangerouslySetInnerHTML={setContenidodom(contenido)}>
								        </p>
								        <div className="bottom-article">
									        <ul className="meta-post">
										        <li><i className="icon-calendar"></i><a href="#!">{fecha_publicacion}</a></li>
									        </ul>
									        <a href="#!" onClick={()=> { history.goBack(); }} className="pull-right">Regresar ...<i className="icon-angle-right"></i></a>
								        </div>
							        </article>
						        </div>
					        </div>
                        </div>
                    </section>
            </Fragment>
         );
    }
}

Articulo = withRouter(Articulo);
export default Articulo;