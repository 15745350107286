import React, { Fragment } from 'react';

const CardAbogado = (props) => {
    const { nombre , apellidos, puesto, correo, principal, id } = props.abogado;
    let special;
    if(principal){
        special =  'pricing-box-alt special'
    }else{
        special = 'pricing-box-alt'
    }
    return ( 
        <Fragment>
        <div className="col-lg-4">
            <div className={special}>
			    <div className="pricing-heading">
				    <h3>{nombre} <strong>{apellidos}</strong></h3>
			    </div>
			    <div className="pricing-terms">
				    <h6>{puesto}</h6>
				    <a href={`mailto:${correo}`}><h6>{correo}</h6></a>
			    </div>
			    <div className="pricing-content">
				    <ul>
				    </ul>
			    </div>
			    <div className="pricing-action">
				    <a href="#!" id="" onClick={() => {props.modalCv(id)}} className="btn btn-medium btn-theme cv-card"><i className="icon-bolt"></i> Perfil</a>
			    </div>
		    </div>
	    </div>
        </Fragment>
     );
}
 
export default CardAbogado;