import React, { useState } from 'react';
import axios from 'axios';

const FormularioContacto = (props) => {
	const stateVacion = {
		name : '',
		email : '',
		subject : '',
		message : '',
		terminos : false
	}

	const [formulario, guardarFormulario] = useState(stateVacion);
	

	const enviarCorreo = async (e) =>{
		e.preventDefault();
		const errmsg = document.getElementById('errormessage');
		const errform = document.getElementById('errormessageForm');
		const success = document.getElementById('sendmessage');
		const err = document.getElementById('errormessageAjax')

		const { name, email, subject, message, terminos } = formulario;
		if(!terminos){
			errmsg.classList.add('show')
			return;
		}
		errmsg.classList.remove('show')

		if(name === '' || email === '' || subject === '' || message === ''){
			errform.classList.add('show')
			return;
		}
		errform.classList.remove('show')		

		//Enviar formulario
		//enviarFormulario(formulario)
		const headers = {
            // 'Accept': 'application/json',
            // 'Content-type': 'application/json',
            'X-API-KEY' : 'hQwiBDmtnENJ3nRKg9yCAA2DUoD}TJf8'
		}
		const data = {
			subject,
			name,
			email,
			message
		}
		
		const url = 'https://www.bracfirmalegal.com/contactform/contactform-react.php'
		const response = await axios.post(url,JSON.stringify(data),{
			headers
		});

		const { msg } = response.data;

		if(msg === 'OK'){
			guardarFormulario(stateVacion);
			success.classList.add('show');
			err.classList.remove('show')	
		}else{
			err.classList.add('show');
		}
	}

	const actualizarState = (e) => {
		guardarFormulario({
			...formulario,
			[e.target.name] : e.target.type === 'checkbox' ? e.target.checked : e.target.value
		})
	}

    return ( 
        <div className="container">
			<div className="row">
				<div className="col-md-8 col-md-offset-2">
					<h4><span>Formulario de contacto</span></h4>
					<div id="sendmessage">Su mensaje ah sido enviado. Muchas gracias! Recibira respuesta en menos de 24 horas.</div>
					<div id="errormessage">Debe de aceptar los terminos y condiciones</div>
					<div id="errormessageForm">Todos los campos son obligatorios</div>
					<div id="errormessageAjax">Surgio un error al enviar el correo, favor de volver a intentarlo</div>
					<form className="contactForm" onSubmit={enviarCorreo}>
						<div className="form-group">
							<div className="input-group">
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input value={formulario.name} onChange={actualizarState} type="text" name="name" className="form-control" id="name" placeholder="Nombre" data-rule="minlen:4" data-msg="Por favor escribir minimo 4 caracteres" />
							</div>
							<div className="validation"></div>
						</div>
						<div className="form-group">
							<div className="input-group">
								<span className="input-group-addon"><i className="fa fa-envelope-o"></i></span>
								<input value={formulario.email} onChange={actualizarState}  type="email" className="form-control" name="email" id="email" placeholder="Correo Electrónico" data-rule="email" data-msg="Por favor escribir un correo valido" />
							</div>
							<div className="validation"></div>
						</div>
						<div className="form-group">
							<div className="input-group">
								<span className="input-group-addon"><i className="fa fa-font"></i></span>
								<input value={formulario.subject} onChange={actualizarState}  type="text" className="form-control" name="subject" id="subject" placeholder="Asunto" data-rule="minlen:4" data-msg="Por favor escribir al menos 8 caracteres para el asunto" />
							</div>
							<div className="validation"></div>
						</div>
						<div className="form-group">
							<textarea value={formulario.message} onChange={actualizarState}  className="form-control" name="message" rows="5" data-rule="required" data-msg="Por favor escribir un mensaje" placeholder="Mensaje"></textarea>
							<div className="validation"></div>
						</div>
						<div className="form-group text-center">
							<label className="checkbox-inline">
								<input onChange={actualizarState} checked={formulario.terminos}  type="checkbox" name="terminos" id="terminos" data-rule="checked" data-msg="Debe de aceptar los terminos y condiciones" /> Acepta los términos y condiciones de la Política de Privacidad <a href="#!" className="aviso-view">Ver</a>
								<div className="validation" id="valchk"></div>
							</label>
						</div>
						<div className="text-center"><button type="submit" className="btn btn-theme">Enviar Mensaje</button></div>
					</form>
				</div>
			</div>
		</div>
     );
}
 
export default FormularioContacto;