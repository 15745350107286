import React, { Component, Fragment } from "react";
import BreadCrumb from "./BreadCrumb";
import ErrorArticulos from "./ErrorArticulos";
import Articulos from "./Articulos";
import axios from "axios";
import { isArray } from "lodash";

class Blog extends Component {
  state = {
    articulos: [],
  };

  consultarBlog = async () => {
    const url = `https://www.bracfirmalegal.com/blog-json.php`;
    const headers = {
      Accept: "application/json",
      "Content-type": "application/json",
      "X-API-KEY": "hQwiBDmtnENJ3nRKg9yCAA2DUoD}TJf8",
    };
    const request = await axios
      .get(url, {
        headers,
      })
      .catch((err) => {
        console.log(err);
        return [];
      });

    let arrayReturn = [];
    if (isArray(request.data) && request.data && request.data.length > 0) {
      arrayReturn = request.data.map((art) => {
        return {
          titulo: `${art["titulo"]}`,
          url_img: `${art["url_img-lg"]}${art["portada"]}`,
          url_detalle: `/articulo/${art["id_articulo_md5"]}`,
          fecha_publicacion: `${art["fecha_publicacion"]}`,
          descripcion_corta: `${art["descripcion_corta"]}`,
        };
      });
    }

    return arrayReturn;
  };

  async componentDidMount() {
    if (this.state.articulos.length === 0) {
      const request = await this.consultarBlog();
      this.setState({
        articulos: request,
      });
    }
  }

  render() {
    let validacion;
    if (this.state.articulos.length === 0) {
      validacion = <ErrorArticulos />;
    } else {
      validacion = null;
    }
    return (
      <Fragment>
        <BreadCrumb titulo="Blog" />
        <section id="content">
          <div className="container">
            {validacion}
            {this.state.articulos.map((articulo, index) => {
              return <Articulos articulo={articulo} key={index} />;
            })}
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Blog;
