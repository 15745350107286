import React, { Fragment, Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import loadjs from 'loadjs';


import Header from './components/Header';
import Footer from './components/Footer';
import Principal from './components/Principal';
import Acerca from './components/Acerca';
import Abogados from './components/Abogados';
import Blog from './components/Blog';
import Contacto from './components/Contacto';
import AreasPractica from './components/AreasPractica';
import Articulo from './components/Articulo';


import NotFound from './components/404';

import './css/bootstrap.min.css';
import './css/fancybox/jquery.fancybox.css';
import './css/jcarousel.css';
import './css/flexslider.css';
import './css/style.css';
import './css/default.css';
import './App.css'
import Filosofia from './components/Filosofia';

class App extends Component {
  state = { }

  componentWillMount(){
    this.loadScripts();
  }

  loadScripts = () =>{
    loadjs('/js/jquery.js',() => {
      loadjs('/js/jquery.easing.1.3.js')
      loadjs('/js/bootstrap.min.js')
      loadjs('/js/jquery.fancybox.pack.js')
      loadjs('/js/jquery.fancybox-media.js')
      loadjs('/js/google-code-prettify/prettify.js')
      loadjs('/js/portfolio/jquery.quicksand.js')
      loadjs('/js/portfolio/setting.js')
      loadjs('/js/jquery.flexslider.js')
      loadjs('/js/animate.js')
      setTimeout(() => {
        loadjs('/js/custom.js')
      },500)
    })
  }

  render(){

  return (
    <Fragment>
      <Router>
        <div id="wrapper">
          <Header />
              <Switch>
                <Route exact path="/" render={() => <Principal reload={this.loadScripts} /> }/>
                <Route exact path="/acerca" render={() => <Acerca reload={this.loadScripts} /> }/>
                <Route exact path="/abogados" render={() => <Abogados reload={this.loadScripts} /> }/>
                <Route exact path="/blog" render={() => <Blog reload={this.loadScripts} /> }/>
                <Route exact path="/filosofia" render={() => <Filosofia reload={this.loadScripts} /> }/>
                <Route exact path="/contacto" render={() => <Contacto reload={this.loadScripts}  /> }/>
                <Route exact path="/areas" render={() => <AreasPractica reload={this.loadScripts} /> }/>
                <Route path="/articulo/:id" render={() => <Articulo reload={this.loadScripts} /> } />
                <Route component={NotFound} />
              </Switch>
          <Footer />
        </div>
      </Router>
    </Fragment>
  );
  }
}

export default App;
