import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import logo from './../img/SmallLogo.jpeg'

let Header = (props) => {
    const style = {
        maxWidth :'30%'
	}
	
	const getNavLinkClass = (path) => {
        return props.location.pathname === path ? 'active' : '';
    }

    return ( 
        <Fragment>
            <header>
			<div className="navbar navbar-default navbar-static-top">
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<a className="navbar-brand" href="/"><img src={logo} alt="" className="img-responsive" style={style} /></a>
					</div>
					<div className="navbar-collapse collapse ">
						<ul className="nav navbar-nav">
							<li className={getNavLinkClass('/')}>
								<NavLink to="/">Inicio</NavLink>
							</li>
							<li className={getNavLinkClass('/acerca')}>
								<NavLink to="/acerca">Acerca de Nosotros</NavLink>
							</li>
							<li className={getNavLinkClass('/abogados')}>
								<NavLink to="/abogados">Abogados</NavLink>
							</li>
							<li className={getNavLinkClass('/filosofia')}>
								<NavLink to="/filosofia">Filosofia BRAC</NavLink>
							</li>
							<li className={getNavLinkClass('/blog')}>
								<NavLink to="/blog">Blog</NavLink>
							</li>
							<li className={getNavLinkClass('/contacto')}>
								<NavLink to="/contacto">Contacto</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</div>
		    </header>
        </Fragment>
     );
}

Header = withRouter(Header);
export default Header;