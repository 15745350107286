import React from 'react';
import { withScriptjs,withGoogleMap,GoogleMap,Marker, } from "react-google-maps";

const GoogleMarker = withScriptjs(withGoogleMap(props => {
    const { latitud , longitud, zoom } = props.marcador;
    console.log(props.marcador);
    return (
        <GoogleMap defaultZoom={zoom} defaultCenter={{ lat:latitud, lng: longitud}}>
            <Marker position={{ lat:latitud, lng: longitud}} />
        </GoogleMap>
    )
}));

export default GoogleMarker;