import React from 'react';
import { NavLink } from 'react-router-dom';

const Articulos = (props) => {
    const { titulo, url_img, url_detalle, fecha_publicacion, descripcion_corta } = props.articulo;
    return ( 
        <article>
			<div className="post-image">
				<div className="post-heading">
					<h3><a href="#!">{titulo}</a></h3>
				</div>
				<img src={url_img} alt={titulo} />
			</div>
			<p>
				{descripcion_corta}
			</p>
			<div className="bottom-article">
				<ul className="meta-post">
					<li><i className="icon-calendar"></i><a href="#!">{fecha_publicacion}</a></li>
				</ul>
                <NavLink className="pull-right" to={url_detalle} >Ver mas ...<i className="icon-angle-right"></i> </NavLink>
			</div>
		</article>
     );
}
 
export default Articulos;