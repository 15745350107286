import React from 'react';
import { NavLink } from 'react-router-dom';

const BreadCrumb = (props) => {
    return ( 
        <section id="inner-headline">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<ul className="breadcrumb">
							<li>
								<NavLink to="/">
									<i className="fa fa-home"></i>
								</NavLink>
								<i className="icon-angle-right"></i></li>
							<li className="active">{props.titulo}</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
     );
}
 
export default BreadCrumb;