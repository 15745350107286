import React, { Fragment } from "react";
import BreadCrumb from "./BreadCrumb";
const AreasPractica = (props) => {
  return (
    <Fragment>
      <BreadCrumb titulo="Áreas de Practica" />
      <section id="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#one" data-toggle="tab">
                    <i className="icon-briefcase"></i> Derecho Corporativo
                  </a>
                </li>
                <li>
                  <a href="#two" data-toggle="tab">
                    Derecho Laboral | Relaciones Laborales
                  </a>
                </li>
                <li>
                  <a href="#three" data-toggle="tab">
                    Derecho Familiar
                  </a>
                </li>
                <li>
                  <a href="#four" data-toggle="tab">
                    Derecho Civil y Mercantil
                  </a>
                </li>
                <li>
                  <a href="#five" data-toggle="tab">
                    Recuperación Cartera Vencida
                  </a>
                </li>
              </ul>
              <div className="tab-content text-justify">
                <div className="tab-pane active" id="one">
                  <p>
                    Hoy en día el Derecho Corporativo es la rama en que se
                    centran las necesidades y todo lo relacionado con las
                    empresas.
                  </p>
                  <p>
                    Las sociedades, asociaciones y agrupaciones pueden
                    conformarse o modificarse de acuerdo a su función, actividad
                    e intereses en cualquiera de los tipos de Sociedad que
                    existen en el país; por ello, es necesaria la mancuerna de
                    una Consultoría Legal en las decisiones que se toman al
                    interior de la empresa, y que les permita elegir la forma
                    más adecuada desde una óptica jurídica para constituirse.
                  </p>
                  <p>
                    Dentro de las Sociedades Mercantiles, es trascendental
                    establecer los alcances, limitantes y responsabilidades de
                    los socios y administradores, lo cual debe depender de la
                    naturaleza del negocio y la modalidad jurídica de cada uno.
                  </p>
                  <p>
                    El crecimiento y las perdidas económicas dentro de las
                    Compañías, deben basarse en estrategias y recomendaciones
                    legales, que disminuya y administre los riesgos corporativos
                    a los que están expuestos.
                  </p>
                  <p>
                    Proteger a la empresa es una obligación legal, que destina
                    su función principalmente a desarrollar mecanismos
                    comerciales para cuidar las relaciones con clientes,
                    proveedores, instituciones públicas o privadas, desde las
                    necesidades contractuales y características particulares
                    desde un enfoque legal-comercial.
                  </p>
                </div>
                <div className="tab-pane" id="two">
                  <p>
                    Actualmente las relaciones laborales se encuentran mermadas
                    en un Capitalismo innecesario, por ello, buscamos una
                    mancuerna legal que propicie recomendaciones legales para
                    que influyan en la equidad y reciprocidad que debe permear
                    en las relaciones de trabajo; esto, ayudará a disminuir
                    pasivos laborales, rotación de personal y ejecución de
                    bajas, bajo la premisa de que ambos sectores sin
                    indispensables para el desarrollo económico de un país.
                  </p>
                  <p>
                    La implementación de políticas laborales, ayudan a centrar
                    los esfuerzos de la Compañía de forma integral, valorando
                    desde un enfoque jurídico, la contratación de personal,
                    relación con Sindicatos, el clima laboral, la búsqueda de un
                    equilibrio entre trabajo y vida social, las percepciones y
                    compensaciones del trabajador, la capacitación y
                    adiestramiento del patrón e incluso, la forma y método de la
                    terminación de las relaciones laborales. De esta forma,
                    aumenta la productividad y, por lo tanto, el impacto
                    financiero será en sentido favorable para la Compañía.
                  </p>
                  <p>
                    El Litigio Laboral es una parte de la naturaleza económica
                    de las empresas y personas, por lo tanto, estamos expuestos
                    contantemente a ello, debiendo afrontarse por medio de la
                    experiencia práctica y teórica ante la solución de
                    conflictos laborales; especializándonos en la protección de
                    los intereses y derechos de las personas y empresas, que
                    hayan considerado vulnerados sus derechos humanos y
                    laborales.
                  </p>
                </div>
                <div className="tab-pane" id="three">
                  <p>
                    Es un elemento natural y fundamental de la sociedad, que
                    funge como herramienta de vital importancia para cuidar,
                    proteger y defender los derechos de la familia.
                  </p>
                  <p>
                    El Derecho Familiar, debe concebirse como la aplicación del
                    conjunto de normas jurídicas que regulan, limitan o ponderan
                    las relaciones personales y patrimoniales de los miembros de
                    la familia para con ellos y frente a terceros.
                  </p>
                  <p>
                    Por ende, y derivado de la naturaleza jurídica de las
                    relaciones en la familia, es decir entre los sujetos y sus
                    efectos y alcances legales, es indispensable solicitar la
                    intervención del Estado, por medio de un juicio que pretenda
                    el cuidado y defensa de las prerrogativas u obligaciones de
                    las personas y los bienes de sus integrantes.
                  </p>
                  <p>
                    Lo anterior, se refiere de manera exclusiva a normas de
                    orden público e interés social, que regulan y protegen a la
                    familia y sus integrantes, así como su organización y
                    desarrollo integral, sobre la estricta observancia a los
                    derechos de igualdad, no discriminación, protección del
                    menor y respeto a la dignidad humana, establecidos en la
                    Constitución Política de los Estados Unidos Mexicanos y los
                    Tratados Internaciones ratificados por México.
                  </p>
                </div>
                <div className="tab-pane" id="four">
                  <p>
                    Es fundamental la aplicabilidad del conjunto de normas e
                    instituciones jurídicas, con relación a los principios del
                    Derecho que permitan limitar o regular las relaciones
                    personales o patrimoniales, voluntarias o forzosas, en las
                    que se desenvuelven las personas privadas o públicas.
                  </p>
                  <p>
                    El Derecho Civil y el Derecho Mercantil, forman una alianza
                    y gran porción del Derecho en General, conocido como Derecho
                    Privado, ambas permiten lograr y comulgar sobre las
                    relaciones personales, es decir, tienden a aportar
                    seguridad, certeza jurídica y justicia a la convivencia
                    humana, que forja durante el tiempo, relaciones personales y
                    de negocio, que deben basarse siguiendo recomendaciones y
                    opiniones jurídicas a través de una Consultoría Legal
                    especializada.
                  </p>
                  <p>
                    La búsqueda de opiniones legales, permite blindar
                    inseguridades en materia comercial, cuidando y regulando
                    todos los actos de comercio, los préstamos, las garantías,
                    los contratos de arrendamiento, de comodato, de fianza, de
                    comisión mercantil, de suministro, de imagen e infinidad de
                    contextos jurídicos, bajo los escenarios de orden Civil y
                    Comercial.
                  </p>
                </div>
                <div className="tab-pane" id="five">
                  <p>
                    Gestionamos todos los canales de cartera vencida: temprana,
                    preventiva, extrajudicial y judicial, con un equipo de
                    Agentes de Recuperación presencial en campo, carteo masivo,
                    blaster en mensajería masiva y centro de llamadas (call
                    center).
                  </p>
                  <p>
                    Nos enfocamos en superar las expectativas de los clientes,
                    incrementamos los resultados contra metas establecidas o
                    años anteriores y nos orientamos en la mejora constante.
                  </p>
                  <p>
                    Apostamos a la implementación de innovación tecnológica que
                    nos permita identificar áreas de oportunidad, organización
                    de carteras y medición de resultados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AreasPractica;
