import React from 'react';

const ErrorArticulo = (props) => {
    return ( <div className="row">
    <div className="col-md-12 text-center">
        <hr />
        <h2>Sin información para mostrar</h2>
        <hr />
        <br />
        <br />
        <br />
    </div>
</div> );
}
 
export default ErrorArticulo;