import React,  {Fragment} from 'react';
import BreadCrumb from './BreadCrumb';

const Acerca = (props) => {
    return ( 
        <Fragment>
            <BreadCrumb titulo="Acerca de Nosotros" />
            <section id="content">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h3>Misión</h3>
						<span className="pullquote-left margintop10 text-justify">
							<p>Somos una firma jurídica joven, con experiencia y sagacidad para ser participe en el control de riesgos en los que se encuentra ligada la vida social de las personas y la actividad empresarial; buscando coadyuvar con nuestros clientes a través de recomendaciones legales, que nos permita de forma mancomunada mitigar los riesgos y agregar un valor jurídico en la vida cotidiana, los procesos, las políticas, las estrategias o contingencias legales. </p>
							<p>Nuestra ideología es ofrecer un servicio diferencial, que genere empatía y sinergía con nuestros clientes, <b>bajo la premisa de que la protección de sus derechos, es el reflejo de nuestro éxito</b>. </p>
							<p>Y, que lo anterior, nos permita ser una Consultoría que forme alianzas y relaciones de trabajo de larga duración, para lograr que el servicio y los excelentes resultados sean el estandarte que caracterice nuestra firma.</p>
                        </span>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="solidline">
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<h3>Visión</h3>
							<span className="pullquote-left margintop10 text-justify">
								<p>Surge con el objetivo de ser una de las firmas con mayor representatividad y presencia a nivel Local y Nacional, instituyendo la cultura de GANAR-GANANDO; en el entendido, de que la equidad en las relaciones interpersonales y de negocio, son la base del éxito.</p>
								<p>El objetivo, se enfoca en ser parte primordial en la mejora del sector social, contribuyendo a las relaciones laborales en beneficio del entorno empresarial y obrero, priorizando la Conciliación en todos los casos, y dejando claro que ambos sectores son indispensables para el desarrollo constante del mundo laboral.</p>
								<p>Otra de las prioridades de BRAC Firma Legal, es blindar las actividades comerciales, operativas y personales de nuestros clientes, con opiniones jurídicas y estrategias de Litigio que permitan su protección y crecimiento a largo plazo.</p>
                            </span>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="solidline">
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<h3>Valores</h3>
								<span className="pullquote-left margintop10">
									<p>La cultura de nuestra firma, está encauzada principalmente, por los valores de humanidad, lealtad, gratitud, pasión, respeto, sinceridad, responsabilidad y honestidad.</p>
                                </span>
							</div>
                        </div>    
                    </div>
                </div>
            </div>
			</section>
        </Fragment>
     );
}
 
export default Acerca;