import React, { Component, Fragment } from 'react';
import BreadCrum from './BreadCrumb';
import GoogleMarker from './GoogleMarker'
import FormularioContacto from './FormularioContacto';

class Contacto extends Component {
    state = { 
        marcador : {
            latitud : 18.907089,
            longitud : -99.232106,
            zoom : 13
        }
    }
    componentDidMount(){
        this.props.reload();
    }

    render() { 
        return (
            <Fragment>
                <BreadCrum titulo="Contacto" />
                <section className="content">
                <div className="map">
                    <GoogleMarker className="map" marcador={this.state.marcador} googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCDSDCRenHmN5N4h_NGRVAlSQbaw41N4PY"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `450px` }} />}
                        mapElement={<div style={{ height: `100%` }} />} />
                </div>
                    <FormularioContacto/>
                </section>
            </Fragment>
         );
    }
}
 

export default Contacto;