import React, { Fragment } from "react";
import BreadCrumb from "./BreadCrumb";

const Filosofia = () => {
  return (
    <Fragment>
      <BreadCrumb titulo="Filosofìa de Negocio BRAC" />
      <section id="content">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
						{/* <h3></h3> */}
						<span className="pullquote-right margintop10 text-justify">
							<b><p>El profesionalismo y liderazgo de los Titulares de las Divisiones y el compromiso de nuestro Equipo de trabajo nos ha permitido expandir nuestra Firma al mantener un diálogo directo y empático en la toma de decisiones, visualizando sus problemáticas como retos propios para generar opiniones e intervenciones litigiosas de éxito. </p>
                            <br/>
							<p>Reinventamos la forma tradicional de Consultoría a través de infraestructura y tecnología con diferentes plataformas que nos ayudan a mantener la calidad de nuestro servicio y así, potencializar la presencia de la Firma en el sector empresarial a lo largo del país. </p>
                            </b>
                        </span>
					</div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Filosofia;
