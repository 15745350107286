import React, { Fragment, Component } from 'react';
import BreadCrumb from './BreadCrumb'
import CardAbogado from './CardAbogado';

class Abogados extends Component {
    state = {
        abogados : [
            { id: 1, nombre : 'CHRISTIAN', apellidos : 'BONOLA', puesto : 'Director General' , correo : 'christian.bonola@bracfirmalegal.com' , principal : true, CV : `<p>Abogado especialista en Derecho del Trabajo, se ha desempeñado como impartidor de justicia Laboral y Burocrática en el Tribunal Estatal de Conciliación y la Junta Local de Conciliación y Arbitraje.</p>
            <p>Ha representado al sector obrero y patronal en la solución de conflictos Laborales y Administrativos, forjando su experiencia a través de litigio, cursos, talleres, diplomados y conferencias en beneficio de las <b>Relaciones Laborales</b>.</p>
            <p>Incursionó su campo profesional en la iniciativa privada como Abogado Comercial, teniendo a su cargo la revisión de contratos colectivos de trabajo, relación con Sindicatos, integración y planeación de estrategias y procesos para prevención de riesgos laborales, disminución de pasivos laborales, coordinación y operación de desvinculaciones de personal masivas e individuales, priorizando en todo momento la Conciliación en beneficio del sector empresarial.</p>
            <p>Ha forjado su experiencia en la estructura, elaboración, revisión y negociación de contratos comerciales de inversión, de imagen y de suministro, blindando los lineamientos y acuerdos realizados ante la Comisión Federal de Competencia Económica, que beneficien los intereses del sector empresarial.</p>
            <p>Experiencia en la negociación con Autoridades Estatales, Municipales y representantes por elección de usos y costumbres en Poblados, para consolidar relaciones legales y comerciales. </p>
            <p>Abogado Comercial, especialista en Derecho del Trabajo, se ha desempeñado como impartidor de justicia Laboral en el Tribunal Estatal de Conciliación y la Junta Local de Conciliación y Arbitraje del Estado de Morelos. </p>
            <p>Ha representado al sector obrero, patronal y de la Administración Pública Municipal en la solución de conflictos Laborales, Administrativos y Sindicales, forjando su experiencia a través del litigio, de cursos, diplomados y conferencias en beneficio de las Relaciones Laborales.
            </p>
            <p>Asesor Laboral y Comercial en la iniciativa privada, ejecutando revisión de contratos colectivos de trabajo, relación con Sindicatos, integración y planeación de estrategias y procesos para prevención de riesgos laborales, disminución en pasivos laborales, atención a la STPS, mancuerna con áreas internas de RRHH y Legal para desarrollar técnicas de negociación, coordinación y operación de desvinculaciones de personal masivas e individuales y bajas conflictivas de mandos medios y superiores; priorizando la Conciliación. 
            </p>
            <p>Consultor y Representante Legal en Litigios de empresa para negociación, estructura, elaboración y revisión de contratos comerciales de inversión, de imagen, de arrendamiento, de consigna, de suministro, de prestación de servicios, de comodato, de compraventa, de fianza, de garantía hipotecaria y similares, con experiencia en Litigio Civil y Mercantil, en enfoque para recuperación de activos.
            </p>
            <p>Participación como Columnista de Opinión en el periódico PROCIVAC, abordando tópicos Jurídico-Laborales en los tres sectores: sindical, empresarial y obrero.
            </p>
            <p>Profesor de Derecho, como Titular de las materias Derecho Inmobiliario, Derecho Mercantil, Derecho Individual del Trabajo y Derecho Colectivo del Trabajo.
            </p>
            `},
            { id: 2, nombre : 'ESTEBAN ', apellidos : 'MORENO', puesto : 'Socio | Titular Litigios Laborales' , correo : 'esteban.moreno@bracfirmalegal.com' , principal : false, CV : `
            <p>Abogado Postulante especialista en Derecho Laboral y Seguridad Social, con más de 15 años de experiencia en Litigio en materia Laboral, ha dirigido la representación legal de las tres bases en el núcleo de las relaciones de trabajo, protegiendo y en la lucha al sector obrero, manteniendo en beneficio y negociación de las relaciones colectivas y buscando ahorros financieros con la proyección de riesgos al sector empresarial. 
            </p>
            <p>Su participación y opinión en la recomendación de las estrategias laborales ha permitido consolidar al Despacho como una de las Firmas de Abogados más jóvenes y con mayor representatividad en la opinión pública y procesal en el mercado de litigio laboral. 
            </p>
            `},
            { id: 3, nombre : 'HEIDI', apellidos : 'MALDONADO', puesto : 'Dirección | Logistica y Operacion Cobranza Legal' , correo : 'heidi.maldonado@bracfirmalegal.com' , principal : false, CV : `
            <p>Administradora especialista en la implementación de estrategias, mejora de mecanismos, diálogos de conciliación y negociación, estructura de equipos y logística de Recuperación de Cartera, desempeñándose en diferentes Firmas Jurídicas de Recuperación de Cartera Vencida. 
            </p>
            <p>Actualmente es responsable de la división de Recuperación de Cartera Vencida de BRAC Firma Legal, liderando a nivel nacional las Coordinaciones de Zona de nuestras diferentes oficinas en el país, buscando la mejora de resultados y el reconocimiento como una de las Firmas más importantes en gestión de cobranza.  
            </p>
            <p>Su participación, organización y logística ha llevado a consolidar al Despacho como una de las Firmas de Abogados más jóvenes y con mayor representatividad en el sector Bancario y Financiero, representando extra y judicialmente a diferentes Instituciones.  
            </p>
            `},
        ],
        abogado : {  },
    }

    modalCv = (id) =>{
        const abogados = this.state.abogados;
        let encontrado = abogados.find(x => x.id === id);
        
        this.setState({
            abogado : encontrado
        })
    }

    componentDidMount() {
        this.props.reload();
    }

    render(){
    return ( 
        <Fragment>
            <BreadCrumb titulo="Abogados" />
            <section id="content">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
					</div>
                    {this.state.abogados.map(abogado => ( <CardAbogado abogado={abogado} key={abogado.id} modalCv={this.modalCv} />))}
                </div>
            </div>
            </section>
            <div className={`modal fade cv-modal`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title"><i className="fa fa-user"></i> &nbsp; {this.state.abogado.nombre}<strong>{' '}{this.state.abogado.apellidos}</strong></h4>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12 text-justify" dangerouslySetInnerHTML={{__html : this.state.abogado.CV}}>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
            </div>
        </div>
        </Fragment>
     );
    }
}
 
export default Abogados;