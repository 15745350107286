import React, { Component, Fragment } from "react";

// import areas from '../img/slides/leyes1.jpeg'
// import quienes from '../img/slides/leyes2.jpeg';
// import blog from '../img/slides/leyes3.jpeg';

import firma from "../img/slides/new-1.jpg";
import building from "../img/slides/new-2.jpg";
import sala from "../img/slides/new-3.jpg";
// import building2 from '../img/slides/new-4.jpg';

import Slider from "./Slider";
import Box from "./Box";

class Principal extends Component {
  state = {
    slider: [
      {
        id: 1,
        img: firma,
        styles: { width: "100%", height: "100%" },
        titulo: "Áreas de Práctica",
        mensaje:
          "Somos un despacho con amplia experiencia en distintas especialidades legales.",
        ruta: "/areas",
      },
      {
        id: 2,
        img: building,
        styles: { width: "100%", height: "100%" },
        titulo: "¿Quiénes Somos?",
        mensaje:
          "Podrás conocer un poco más de nosotros, y cómo podemos ayudarte oportunamente.",
        ruta: "/acerca",
      },
      {
        id: 3,
        img: sala,
        styles: { width: "100%", height: "100%" },
        titulo: "Blog Personal",
        mensaje:
          "Conoce e infórmate sobre las novedades y cambios en la ley, que practicámos a través de nuestras labores.",
        ruta: "/blog",
      },
    ],
    boxes: [
      {
        id: 1,
        titulo: "Derecho Corporativo",
        icono: "fa fa-building-o fa-3x",
        mensaje:
          "Las <b>Estrategias Legales</b> apropiadas son fundamentales para impactar en el crecimiento constante de la Empresa, administrando los riesgos corporativos que puedan influir en la perdida directa de capital. <br /> <br/>",
        ruta: "/areas",
      },
      {
        id: 2,
        titulo: "Derecho Laboral y Seguridad Social",
        icono: "fa fa-cogs fa-3x",
        mensaje:
          "Cuidar el <b>Capital Humano</b>, es cuidar de la empresa; lo cual, se puede formar mediante estrategias legales para la mejora continua de la relación obrero-patronal, cuya función es, impactar radicalmente en beneficio de la Empresa.",
        ruta: "/areas",
      },
      {
        id: 3,
        titulo: "Derecho Familiar",
        icono: "fa fa-group fa-3x",
        mensaje:
          "La familia es el núcleo de una sociedad, por lo tanto, es a través de las Leyes que debe protegerse lo más valioso dentro del esquema y modelo establecido en la sociedad mexicana. <br/> <br/> <br/>",
        ruta: "/areas",
      },
      {
        id: 4,
        titulo: "Derecho Civil y Mercantil",
        icono: "fa fa-dollar fa-3x",
        mensaje:
          "Funge como una de las ramas principales del Derecho, que permite el estudio y la aplicación de las normas jurídicas para regular la conducta general de la persona. <br/> <br/>",
        ruta: "/areas",
      },
      {
        id: 5,
        titulo: "Recuperación Cartera Vencida",
        icono: "fa fa-money fa-3x",
        mensaje:
          "Somo líderes en la implementación de estrategias para Recuperación de Cartera vencida colaboramos con Instituciones Bancarias y Financieras en todo México, liderados por nuestra Dirección de Logística y Operación | Cobro Legal. ",
        ruta: "/areas",
      },
    ],
  };

  componentDidMount() {
    this.props.reload();
  }

  render() {
    return (
      <Fragment>
        <section id="featured">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div id="main-slider" className="flexslider">
                  <ul className="slides">
                    {this.state.slider.map((slide) => {
                      return <Slider slider={slide} key={slide.id} />;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="callaction">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="big-cta">
                  <div className="cta-text">
                    <h2>
                      <span>ÁREAS DE PRÁCTICA</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <hr className="style-hr" />
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 text-justify">
                <h5>
                  Somos una Firma de Abogados multidisciplinarios con tres
                  principales divisiones: Materia Laboral y Seguridad Social,
                  Consultoría y Litigio Comercial Empresarial y Recuperación de
                  Cartera Vencida.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row scrolling-wrapper-flexbox">
                  {this.state.boxes.map((box) => {
                    return <Box box={box} key={box.id} />;
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="solidline"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4 className="heading" style={{ textAlign: "right" }}>
                  Acerca de BRAC...
                </h4>
                <div className="row container">
                  <span className="pullquote-left margintop10 text-justify">
                    <p>
                      BRAC Firma Legal es un Despacho de Abogados fundado en el
                      Estado de Morelos, con presencia en Cdmx, Toluca,
                      Querétaro, Guerrero, Oaxaca y Guadalajara, entre otros
                      Estados de la República Mexicana.
                    </p>
                    <p>
                      Desde sus inicios adoptó la firme convicción que uno de
                      sus principales diferenciadores sería el nivel de
                      servicio, aspecto que al día de hoy ha permitido sumar a
                      su cartera de clientes a lo largo del país empresas del
                      sector Hotelero, Fibras (Fideicomisos de Bienes Raíces),
                      Banca y Financiera, Alimentos y Bebidas, Higiene y Dental,
                      Desarrollo y Tecnología, Comercio General, entre otros.
                    </p>
                    <p>
                      BRAC es un aliado de negocio con la implementación de
                      estrategias legales que reflejan un impacto positivo en
                      las relaciones comerciales y en ahorros de capital de
                      nuestros clientes, posicionándonos a nivel nacional por
                      nuestro servicio y orientación de resultados.
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4 className="heading">Nuestros Clientes</h4>
                <div className="row container">
                  <span className="pullquote-right margintop10 text-justify">
                    <p>
                      Para BRAC Firma Legal lo más importante son los clientes,
                      sus necesidades son nuestro sentido de urgencia; esto, nos
                      ha permitido sumar en Morelos y otros Estados diversas
                      Dependencias u Organismos de la Administración Pública,
                      micros, medianas y grandes empresas con presencia Nacional
                      e Internacional de diferentes sectores Banca y Financiero,
                      Educación, Hotelero y Restaurantero, Higiene y Dental,
                      Alimentación y Bebidas, entre otros), formando así,
                      alianzas profesionales de larga duración.
                    </p>
                    <p>
                      Participamos en la identificación y administración de
                      riesgos legales mediante opiniones, recomendaciones
                      jurídicas, estructura de políticas y proyectos de mejora o
                      estrategias de litigio, con un amplio sentido de
                      reciprocidad, enalteciendo el profesionalismo, ética y
                      calidad profesional que se imprime a cada asunto
                      encomendado a nuestra Firma, lo cual tiene como resultado
                      el crecimiento y satisfacción de nuestros clientes.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Principal;
